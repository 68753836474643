<template>
  <v-container
    fluid>
    <h2
      class="grey--text my-4 ml-3">
      Tipos de Documento
    </h2>

    <v-container
      fluid
      class="py-0 px-2">
      <DefaultFilter
        :refFilter="'DocumentTypeFilter'"
        :model="documentTypeFilter"
        :filters="filters"
        :showRangeFilter="false"
        :showExpansion="filters.length > 3"
        :loading="loading"
        @search="search"
      />
    </v-container>

    <v-container
      fluid
      class="py-0">
      <v-row
        class="my-3">
        <v-col
          v-if="showForm">
          <DefaultForm
            ref="DefaultForm"
            :formConfig="formConfig"
            :model="form"
          />
        </v-col>

        <v-col
          class="d-flex flex-row justify-space-between"
          :cols="$vuetify.breakpoint.mdAndUp && showForm === true ? 3 : 12">
          <v-row>
            <v-col
              v-if="showForm"
              :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
              <v-btn
                x-large
                block
                @click="handleForm"
                color="primary">
                Salvar
              </v-btn>
            </v-col>

            <v-col
              v-if="showForm"
              :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
              <v-btn
                :class="{
                  'mt-2': $vuetify.breakpoint.xsOnly
                }"
                outlined
                x-large
                block
                @click="finishForm"
                color="primary">
                Cancelar
              </v-btn>
            </v-col>

            <v-col
              v-if="!showForm"
              xl="2"
              lg="2"
              class="ml-auto">
              <v-btn
                outlined
                x-large
                block
                @click="startCreateForm"
                color="primary">
                Nova Configuração
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <DefaultTable
      ref="DocumentTypeTable"
      :items="documentTypes"
      :tableConfig="tableConfig"
      :loading="loading"
      :totalPages="totalPages"
      @itemsPerPage="itemsPerPage = $event"
      @page="page = $event"
      @sort="sort = $event"
      @search="search">
      <template v-slot:no-data>
        <span>
          Nenhum dado encontrado.
        </span>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        {{ item.id }}
      </template>

      <template v-slot:[`item.description`]="{ item }">
        {{ item.description }}
      </template>

      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>

      <template v-slot:[`item.odooDocumentTypeId`]="{ item }">
        {{ item.odooDocumentTypeId ? item.odooDocumentTypeId : '—' }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          transition="slide-y-transition" offset-y>
          <template
            v-slot:activator="{ on, attrs }">
            <v-btn
              color="textPrimary"
              dark
              icon
              v-bind="attrs"
              v-on="on">
              <v-icon>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>

          <TodoListActions
            :actions="getTodoActions(item)"
          />
        </v-menu>
      </template>
    </DefaultTable>


    <v-container
      fluid>
      <DefaultFooter />
    </v-container>

    <SnackbarCustomize
      ref="SnackbarCustomize"
    />
  </v-container>
</template>

<script>
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';
import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin';
import DefaultFooter from '@/components/Default/DefaultFooter/DefaultFooter.vue';
import DefaultFilter from '@/components/Default/DefaultFilter/DefaultFilter.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import DefaultTable from '@/components/Default/DefaultTable/DefaultTable.vue';
import DefaultForm from '@/components/Default/DefaultForm/DefaultForm.vue';
import DocumentTypeService from '@/services-http/sdi/DocumentTypeService';
import TodoListActions from '@/components/TodoList/TodoListActions.vue';
import Rules from '@/shared/validators/formRules';

export default {
  name: "DocumentType",

  components: {
    SnackbarCustomize,
    TodoListActions,
    DefaultFooter,
    DefaultFilter,
    DefaultTable,
    DefaultForm,
  },

  data: () => ({
    create: false,
    loading: false,
    documentTypes: [],
    documentTypeFilter: {},
    form: {
      name: null,
      description: null,
      odooDocumentTypeId: null,
    },
    documentTypeService: null,
    totalPages: 1,
    itemsPerPage: 25,
    page: 1,
    sort: "id,desc",
    showForm: false,
    rule: null,
  }),

  mixins: [
    AuthorityManagementMixin,
    GenericFunctionsMixin,
  ],

  methods: {
    finishForm() {
      this.$refs.DefaultForm.reset();
      this.$refs.DefaultForm.resetValidation();
      this.create = false;
      this.showForm = false;
      this.resetForm();
    },

    resetForm() {
      this.form = {
        name: null,
        description: null,
        odooDocumentTypeId: null,
      }
    },

    getTodoActions(item) {
      const actions = [
        {
          label: 'Editar',
          icon: 'fas fa-pencil-alt',
          color: 'textPrimary',
          condition: true,
          disabled: false,
          handler: () => this.startEditForm(item),
        },
      ];

      return actions;
    },

    async handleForm() {
      if (!this.$refs.DefaultForm.validate()) return;

      this.loading = true;

      try {
        if (this.form.odooDocumentTypeId) {
          this.form.odooDocumentTypeId = parseInt(this.form.odooDocumentTypeId)
        }
        if (this.create === true) {
          await this.documentTypeService.Post(this.form);
        } else {
          await this.documentTypeService.Put(this.form);
        }
      } catch (err) {
        this.$refs.SnackbarCustomize.open('error', err);
      } finally {
        this.loading = false;
        this.search();
        this.finishForm();
      }
    },

    async search() {
      this.loading = true;

      this.documentTypeFilter = this.getFilteredParams(this.documentTypeFilter);
      const params = this.buildSearchParams(this.documentTypeFilter, this.page, this.itemsPerPage, this.sort);

      await this.documentTypeService.FindAllByFilter(params)
        .then((response) => {
          this.totalPages = response.data.totalElements;
          return response.data.content;
        })
        .then((result) => this.documentTypes = result)
        .catch((err) => this.$refs.SnackbarCustomize.open('error', err))
        .finally(() => this.loading = false);
    },

    startCreateForm() {
      this.create = true;

      this.showForm = true;
    },

    startEditForm(item) {
      this.create = false;

      this.showForm = true;

      this.form = { ...item };
    },
  },

  computed: {
    filters() {
      const textFieldProps = {
        dense: true,
        outlined: true,
        clearable: true,
        hideDetails: true,
        color: "primary--text",
        itemColor: "primary--text",
      };

      const colsProps = {
        cols:"12",
        xl:"4",
        lg:"4",
        md:"4",
        sm:"6",
      };

      let fields = [
        {
          name: 'name',
          type: 'textfield',
          attrs: {
            label: 'Nome',
            ...textFieldProps,
          },
          col: {
            attrs: colsProps,
          },
        },
        {
          name: 'description',
          type: 'textfield',
          attrs: {
            label: 'Descrição',
            ...textFieldProps,
          },
          col: {
            attrs: colsProps,
          },
        },
        {
          name: 'id',
          type: 'textfield',
          attrs: {
            label: 'Protocolo',
            ...textFieldProps,
          },
          col: {
            attrs: colsProps,
          },
        },
      ]

      if (this.hasPermission('sdi_tipos_documento_edita_id_externo')) {
        fields.push({
          name: 'odooDocumentTypeId',
          type: 'textfield',
          attrs: {
            label: 'Id do Documento - Odoo',
            ...textFieldProps,
            vMask: "######",
          },
          col: {
            attrs: colsProps,
          },
        })
      }

      return fields;
    },

    formConfig() {
      const propsCol = {
        class: "text-subtitle-1 primary--text font-weight-medium",
        cols: "12",
        md: "4",
        lg: "4",
        xl: "4"
      };

      const propsTextField = {
        class: "font-weight-regular",
        color: "textPrimary",
        outlined: true,
        dense: true,
        hideDetails: true,
      };

      const propsCard = {
        class: "rounded-lg pa-2"
      }

      let form = [
        {
					title: "",
					attrs: propsCard,
					fields: [
						{
              name: 'name',
							type: 'textfield',
							col: {
								attrs: propsCol,
							},
							attrs: {
								...propsTextField,
                rules: [this.rule.required, this.rule.valid],
								label: 'Nome*',
							},
						},
            {
              name: 'description',
							type: 'textfield',
							col: {
								attrs: propsCol,
							},
							attrs: {
                rules: [this.rule.required, this.rule.valid],
								...propsTextField,
								label: 'Descrição*',
							},
						},
					]
				},
      ]

      if (this.hasPermission('sdi_tipos_documento_edita_id_externo')) {
        form[0].fields.push({
          name: 'odooDocumentTypeId',
          type: 'textfield',
          col: {
            attrs: propsCol,
          },
          attrs: {
            ...propsTextField,
            label: 'Id do Documento - Odoo',
            vMask: "######",
          },
        })
      }

      return form;
    },

    tableConfig() {
      const fieldProps = {
        align: 'center',
        class: 'text--truncate',
      };

      let headers = [
        {
          text: 'Protocolo',
          value: 'id',
          slot: false,
          ...fieldProps,
        },
        {
          text: 'Nome',
          value: 'name',
          slot: false,
          ...fieldProps,
        },
        {
          text: 'Descrição',
          value: 'description',
          slot: false,
          ...fieldProps,
        },
      ];

      if (this.hasPermission('sdi_tipos_documento_edita_id_externo')) {
        headers.push({
          text: 'Id do Documento - Odoo',
          value: 'odooDocumentTypeId',
          slot: false,
          sortable: false,
          ...fieldProps,
        });
      }

      headers.push({
        text: 'Ações',
        value: 'actions',
        align: 'end',
        slot: false,
        sortable: false,
      })

      return  {
        itemKey: 'id',
        calculateWidths: true,
        sort: 'id',
        sortDesc: true,
        headers: headers,
      };
    },
  },

  created() {
    this.documentTypeService = new DocumentTypeService();
    this.rule = new Rules();
  },

  async mounted() {
    await this.search();
  },
}
</script>